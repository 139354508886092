import React, { useCallback, useEffect, useRef, useState } from "react";
import { Buffer } from "buffer";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { Editor } from "@tinymce/tinymce-react";
import tinymce from 'tinymce/tinymce';
import 'tinymce/models/dom/model'
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/table';
import 'tinymce/plugins/link';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/code';
import "tinymce/plugins/lists";
import "tinymce/plugins/image";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/quickbars";
import './TinyMCE.style.css'
import './sideClasses.style.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import HighlightWithinTextarea from "react-highlight-within-textarea";
import HandlingResponse from "../../../common/handling-response/handlingResponse"
import useTextEditorContext from "../../hooks/useTextEditorContext";

const TinyMCE = () => {
  const [file, setFile] = useState("");
  let fileHandle;
  const content = file;
  const editorRef = useRef(null);
  const navigate = useNavigate();

  const [id] = useSearchParams();
  var docId = id.get("id");
  const [appPath] = useSearchParams();
  var app = appPath.get("appPath")
  const [optionsVisible] = useSearchParams();
  var options = optionsVisible.get("optionsVisible") ? optionsVisible.get("optionsVisible") : true
  const [fileName] = useSearchParams();
  var htmlName = fileName.get("fileName")
  const [isMainFile] = useSearchParams();  //da li se otavara glavni html fajl ili html fajl iz priloga
  var mainFile = isMainFile.get("isMainFile")
  const [attGuid] = useSearchParams();
  var attachmentGuid = attGuid.get("attGuid")
  
  var parser = new DOMParser();
  const htmlDocument = parser.parseFromString(file, "text/html");
  let regexForStyle =  /<style type="text\/css">([\s\S]*?)<\/style>/;
  let regexForStyle2 =  /type="text\/css">([\s\S]*?)<\/style>/;
  let regexForStyle3 =  /<style>([\s\S]*?)<\/style>/;
  let result = htmlDocument?.head?.innerHTML.match(regexForStyle)
  if (!result) { // u slucaju da prvi regex ne vrati nista probati sa drugim
    result = htmlDocument?.head?.innerHTML.match(regexForStyle2)
  }
  if (!result) { // u slucaju da prethodni regexi ne vrate nista
    result = htmlDocument?.head?.innerHTML.match(regexForStyle3)
  }
  let contentStyle = result ? result[1] : ""  //u contentStyle se nalaze stilovi koji su u <style> bloku u head-u
  let headContent = htmlDocument?.head?.innerHTML;
  headContent = headContent.replace(/&amp;amp;amp;amp;gt;/g, '>');
  headContent = headContent.replace(/&amp;amp;amp;amp;lt;/g, '<');
  headContent = headContent.replace(/&gt;/g, '>');
  headContent = headContent.replace(/&lt;/g, '<');

  const scripts = htmlDocument?.querySelectorAll('head script');

  // const [htmlDocument, setHtmlDocument] = useState();  
  const { getHtml, updateHtmlFile, cancelUpdate } = useTextEditorContext();
  const [textareaContent, setTextareaContent] = useState("")
  const [textToSearch, setTextToSearch] = useState("")
  const [textToReplace, setTextToReplace] = useState('')
  const [hideFindReplaceBox, setHideFindReplaceBox] = useState(true);

  const [seachFormats, setSearchFormats] = useState("");
  const [formats, setFormats] = useState([]);
  const [filterFormats, setFilterFormats] = useState([]);

  const [hideHtmlBody, setHideHtmlBody] = useState(false);
  const [hideHtmlHead, setHideHtmlHead] = useState(true);
  
  const [highlight, setHighlight] = useState([])
  const [clickedText, setClickedText] = useState("")

  const [htmlFromApps, setHtmlFromApps] = useState(false); //true je kad je html iz neke od aplikacija mml, fp...

  //dodaju se objekti u listu za highlight textarea
    useEffect(() => {
      const copy = []
      if(textToSearch !== ""){
        copy.push({
          highlight: String(textToSearch),
          className: "yellow"
        })
        setHighlight(copy)
      }
    }, [textToSearch])

    useEffect(() => {
      const copy = []
      if(clickedText !== ""){
        copy.push({
          highlight: String(clickedText),
          className: "blue"
        })
        setHighlight(copy)
      }
    }, [clickedText])

    const getHmtlFile = async () => {
      var response = await getHtml(docId, app, mainFile, attachmentGuid, htmlName);
      var handledResponse = HandlingResponse(response);
      if (handledResponse != 'Error') {
        const fileContent = Buffer.from(response.data.fileContent.fileContents, 'base64').toString();
        setFile(fileContent)
        setHtmlFromApps(true);
      }
    }

  useEffect(() => {
    getHmtlFile();
  }, [])


  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const textStr = reader.result;
        setFile(textStr);
        setHtmlFromApps(false);
      };

      if (file) {
        reader.readAsText(file);
      }
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "text/html": [".html", ".htm"],
    },
    maxFiles: 1,
  });

  const deleteFile = () => {
    setFile(null);
  };

  const save = async () => {
    let stream = await fileHandle.createWritable();
    htmlDocument.body.innerHTML = editorRef.current.getContent();
    htmlDocument.head.innerHTML = document.getElementById("textAreaHead").value;
    await stream.write(new XMLSerializer().serializeToString(htmlDocument));
    await stream.close();
  };

  const saveFile = async () => {
    fileHandle = await window.showSaveFilePicker();
    save();
  };


  const editFile = async () => {
    if (htmlFromApps) {
      var formData = new FormData();

      htmlDocument.body.innerHTML = editorRef.current.getContent();
      htmlDocument.head.innerHTML = document.getElementById("textAreaHead").value;
      const modifiedHtml = new XMLSerializer().serializeToString(htmlDocument);

      const encoder = new TextEncoder();
      const uint8Array = encoder.encode(modifiedHtml);
      const blob = new Blob([uint8Array], { type: "application/octet-stream" });

      if (mainFile.includes("true"))
        formData.append("Id", docId)
      else {
        if (app.includes("registries"))
          formData.append("LawActId", docId)
        else if (app.includes("mml"))
          formData.append("DataId", docId)
        else if (app.includes("frontpage"))
          formData.append("id", docId)
        else if (app.includes("regulations"))
          formData.append("LawActId", docId)
      }
      if (mainFile.includes("false"))
        formData.append("AttachmentName", htmlName)
      formData.append("File.Guid", "");
      formData.append("File.Name", htmlName);
      formData.append("File.File", blob);
          
      var response = await updateHtmlFile(formData, app, mainFile);
      var handledResponse = HandlingResponse(response)     
    }
    else{
      fileHandle = await window.showSaveFilePicker();
      save();
    }
  }

  const cancel = () => {
    if (htmlFromApps){
      window.close()
    }
    else
      setFile(null);
  }

  const addBorder = () => {
    const tableElements = document.getElementById('kljuc_ifr').contentWindow.document.getElementById('tinymce').getElementsByTagName("table");
    if (tableElements.length > 0) {
      for (let item of tableElements) {
        item.classList.add("tabela-sa-borderom");
      }
    }
    if (options){
      updateHTML(editorRef.current.getContent());
    }
  };

  const addBorderSelect = () => {
    const item = tinymce.activeEditor.selection.getNode();
    if (item.tagName == 'TABLE') {
      item.classList.add("tabela-sa-borderom");
    }
    if (options){
      updateHTML(editorRef.current.getContent());
    }
  };

  const removeBorder = () => {
    const tableElements = document.getElementById('kljuc_ifr').contentWindow.document.getElementById('tinymce').getElementsByTagName("table");
    if (tableElements.length > 0) {
      for (let item of tableElements) {
        item.classList.remove("tabela-sa-borderom");
      }
    }
    if (options){
      updateHTML(editorRef.current.getContent());
    }
  }

  const removeBorderSelect = () => {
    const item = tinymce.activeEditor.selection.getNode();
    if (item.tagName == 'TABLE') {
      item.classList.remove("tabela-sa-borderom");
    }
    if (options){
      updateHTML(editorRef.current.getContent());
    }
  };

  const updateHTML = (content) => {
    // document.getElementById("htmlTextArea").value = content;
    setTextareaContent(content)
  }

  const updateHead = (value) => {
    document.getElementById("textAreaHead").value = value;
  }

  const updateEditor = (value) => {
    setTextareaContent(value)
    if (!tinymce.activeEditor?.hasFocus()) {
      // tinymce.activeEditor.setContent(document.getElementById("htmlTextArea").value);
      tinymce.activeEditor.setContent(value);
    }
    if (textToSearch == "")
      setTextToSearch(null)
  }

  const showFindReplace = () => {
    setTextToSearch("");
    setTextToReplace("");
    setHideFindReplaceBox(false);
  }

  const hideFindReplace = () => {
    setHideFindReplaceBox(true);
    setTextToSearch(null);
    setTextToReplace(null);
  }

  const onChangeSearch = (value) => {
    setTextToSearch(value)
    if (value === "")
      setTextToSearch(null)
  }

  const searchText = () => {
      let element = Array.from(document.querySelectorAll('span')).find(el => el.textContent.includes(textToSearch));
      if (element){
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }

  const replaceText = () => {
    const updatedText = textareaContent.replace(new RegExp(textToSearch, "i"), textToReplace);   // i za case-sensitive
    setTextareaContent(updatedText);
    updateEditor(updatedText);
  }

  const replaceAllText = () => {
    const updatedText = textareaContent.replace(new RegExp(textToSearch, "gi"), textToReplace);  // g za globalno trazenje
    setTextareaContent(updatedText);
    updateEditor(updatedText);
  }

  const removeAlign = () => {
    let updatedText = editorRef.current.getContent().replace(/align="center"|align="left"|align="right"|align="justify"|text-align: center;|text-align: left;|text-align: right;|text-align: justify;/g, '');  // g za globalno trazenje
    setTextareaContent(updatedText);
    tinymce.activeEditor.setContent(updatedText);
  }

  const clearText = (text) => {  //cisti tekst od atributa koje sam dodeljuje tinymce
    let regexHref = / data-mce-href="([\s\S]*?)"/g
    let regexStyle = / data-mce-style="([\s\S]*?)"/g
    text = text.replace(regexHref, '');
    text = text.replace(regexStyle, '');
    // text = text.replace(/&nbsp;/g, '');
    return text;
  }

  const clickOnText = async () => {
    var allNodesInText = [];
    var sameNodesInText = [];
    var allNodesInHtml = [];
    var sameNodesInHtml = [];
    var indexOfClickedNode = 0;
    var scrollElement;

    let nodeText = tinymce.activeEditor.selection.getNode().innerHTML;
    nodeText = clearText(nodeText);
    nodeText = nodeText.replace(/&nbsp;/g, '');

    allNodesInText = Array.from(tinymce.activeEditor.getBody().childNodes);  //svi cvorovi iz teksta
    sameNodesInText = allNodesInText.filter(node => node.innerText === nodeText);  //svi cvorovi u tekstu koji imaju isti sadrzaj teksta
      
      for (let i = 0; i< sameNodesInText.length; i++)
      {
        if (sameNodesInText[i].offsetTop == tinymce.activeEditor.selection.getNode().offsetTop || sameNodesInText[i].offsetTop == tinymce.activeEditor.selection.getNode().offsetTop + 1)
          indexOfClickedNode = i; //indeks cvora na koji je kliknuto
      }
  
      allNodesInHtml =  Array.from(document.querySelectorAll('span[data-text="true"]'));  //svi cvorovi iz html-a

      //za svaki cvor u html-u se izvlaci samo sadrzaj, sklanja se tag <p> koji imaju svi cvorovi u html-u i ostaje samo sad sadrzaj
      allNodesInHtml.forEach(node => {
        let content = '';
        content = node.textContent;
        let firstIndex = content.indexOf('>')
        let lastIndex = content.lastIndexOf('</')
        content = content.substring(firstIndex + 1, lastIndex);
        if (content == nodeText)
          if (sameNodesInText.length > 1)
            sameNodesInHtml.push(node); //svi cvorovi u html-u koji imaju isti sadrzaj
          else 
            scrollElement = node;
      })
        if (sameNodesInHtml.length > 1) { //ako postoji vise istih cvorova, skroluje do odredjenog
          sameNodesInHtml[indexOfClickedNode].scrollIntoView({ behavior: 'smooth', block: 'center' });
          setClickedText(nodeText); //da bi se hajlajtovao taj tekst
        }
        
      if (!scrollElement && sameNodesInHtml.length <= 1)
      //ako u prethodnoj proveri nije pronadjen odgovarajuci cvor do koga se treba skrolovati, uzima se jos jedan slucaj u obzir kao dodatna provera
        scrollElement = Array.from(document.querySelectorAll('span')).find(el => el.textContent.includes(nodeText));  
      if (scrollElement){
        scrollElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setClickedText(tinymce.activeEditor.selection.getNode().outerHTML); //da bi se hajlajtovao taj tekst
      } 
  }

  const clickOnHtml = () => {
    var allNodesInHtml = [];
    var sameNodesInHtml = [];
    var indexOfClickedNode = 0; 
    var clickedText = window.getSelection().anchorNode.textContent;
    var regex = /<([^\s>]+)/
    var nodeTag = clickedText.match(regex)[1]
    let sameNodesInText = [...tinymce.activeEditor.dom.getRoot().querySelectorAll(nodeTag)]
    sameNodesInText = sameNodesInText.filter((item) => clearText(item.outerHTML) === clickedText) //cvorovi u tekstu koji imaju isti tekst

    if (sameNodesInText.length > 1) {  //ako ima vise istih cvorova u tekstu, mora da se pronadje indeks cvora iz html-a na koji je kliknuto

        let firstIndexInClickedText = clickedText.indexOf('>');
        let lastIndexInClickedText = clickedText.lastIndexOf('</')
        clickedText = clickedText.substring(firstIndexInClickedText + 1, lastIndexInClickedText); //izvlaci se samo tekst bez tagova za paragraf

        allNodesInHtml = Array.from(document.querySelectorAll('span[data-text="true"]'));  //svi cvorovi iz html-a

        allNodesInHtml.forEach(node => {
          let content = '';
          content = node.textContent;
          let firstIndex = content.indexOf('>')
          let lastIndex = content.lastIndexOf('</')
          content = content.substring(firstIndex + 1, lastIndex);
          if (content == clickedText)
            sameNodesInHtml.push(node);
        })

        for (let i = 0; i< sameNodesInHtml.length; i++)
        {
          if (sameNodesInHtml[i].offsetTop == window.getSelection().anchorNode.parentElement.offsetTop)
            indexOfClickedNode = i; //indeks cvora na koji je kliknuto u html-u
        }
    }

    const selectNode = sameNodesInText.length > 1 ? sameNodesInText[indexOfClickedNode] : sameNodesInText[0];
    tinymce.activeEditor.selection.select(selectNode);
    selectNode?.scrollIntoView({ behavior: 'smooth', block: 'center' });
}

  const editHead = () => {
    setHideHtmlBody(true);
    setHideHtmlHead(false);
  }

  const cancelEditHead = () => {
    setHideHtmlBody(false);
    setHideHtmlHead(true);
  }


 const getAllFormats = () => {
    var formatsList = tinymce.activeEditor?.formatter.get();
    formatsList && setFormats(Object.entries(formatsList).filter(([key, value]) => value[0].title))
  }

    useEffect(() => {
      getAllFormats()
      extractJSFunctions()
      extractjQueryFunctions()
    }, [tinymce.activeEditor])


    const addFormatStyle = (classKey, classValue) => {
      if (classValue.includes("v2-")) {
        var classToRemove = tinymce.activeEditor.selection.getNode().classList;
        classToRemove = [...classToRemove]
        classToRemove = classToRemove.filter((x) => x.includes("v2-"))[0]  //trazi samno onu klasu koja pocinje sa v2, to su one za *
        //ako selektovani tekst vec ima neku klasu sa zvezdicom i zeli da dodeli novu klasu sa zvezdicom, onda prvo mora da ukloni prethodnu jer ne smeju da se sabiraju klase
        if(classToRemove?.includes("v2-") && classValue?.includes("v2-"))
        {
          classToRemove = formats.filter(([key, value]) => value[0].title === classToRemove)[0]  //uzima onu vrednost klase mce_x
          tinymce.activeEditor.formatter.remove(classToRemove[0])
        }
      }
    
      tinymce.activeEditor.formatter.apply(classKey);
      updateHTML(editorRef.current.getContent())

    }

  const searchFormats = (seachFormats) => {
    var filterFormatsList = formats.filter(([key, value]) => value[0].title && value[0].title.toLowerCase().includes(seachFormats))
    setFilterFormats(filterFormatsList)
  }

  const formatsSideClasses = () => {
    return seachFormats ? filterFormats : formats;
  }


  const extractJSFunctions = () => {
    if(scripts?.length > 0) {
      // Iteriramo kroz svaki `<script>` tag i evaluiramo kod
      scripts.forEach(script => {
        const scriptCode = script.innerText.trim();
        // Regularni izraz za pronalaženje definicija funkcija
        let functionDefinitionRegex = /function\s+([^\s\(]+)\s*\(([^)]*)\)\s*{([\s\S]*?)}/g

        let functions = [];
        let match;

        while ((match = functionDefinitionRegex.exec(scriptCode)) !== null) {
            let functionName = match[1];
            let functionParams = match[2].trim();
            functions.push({ name: functionName, params: functionParams, startIndex: scriptCode.search("function " + match[1]) });
        }

        // functions.forEach(function (func) {
        //     console.log("Ime funkcije:", func.name);
        //     console.log("Parametri funkcije:", func.params);
        //     console.log("Pocetni indeks:", func.startIndex)
        //     console.log("--------------------");
        // });
          extractJSFunctionBody(scriptCode, functions);
      })
    }
  }

  const extractJSFunctionBody = (scriptCode, functions) => {
      functions?.forEach((func) => {
          var openBracesCount = 0;
          var closedBracesCount = 0;
          var functionCode = '';

        for(let i = func.startIndex; i < scriptCode.length ; i++) {
          var char = scriptCode[i];
          
          if(char === '{')
            openBracesCount++;
          else if (char === '}')
            closedBracesCount++;
          if(openBracesCount > closedBracesCount)
            functionCode += char;
          if(openBracesCount === closedBracesCount && openBracesCount > 0) {
            func.body = functionCode.substring(1);
            break;
          }           
        }     
      })
      executeJSFunctions(functions);
  }

  const executeJSFunctions = (functions) => {
      functions?.forEach((func) => {
          func.body = func.body.replace(/document\./gi, "tinymce.activeEditor.getDoc().");
          func.body = func.body.replace(/getElementById\(/gi, "querySelector('#' + ");
          tinymce.activeEditor.getWin()[func.name] = new Function(func.params, func.body);
      })
  }

  const extractjQueryFunctions = () => {   
    if(scripts?.length > 0) {
      // Iteriramo kroz svaki `<script>` tag i evaluiramo kod
      scripts.forEach(script => {
        const scriptCode = script.innerText.trim();
        const regex = /\$\(["']([^"']+)["']\)\.(\w+)\(/g;
        let match;
        let functions = [];
        while ((match = regex.exec(scriptCode)) !== null) {
            // console.log("Selektor:", match[1]);
            // console.log("Dogadjaj:", match[2]);
            // console.log();

            if (match[1].includes(',')) //ako u selektoru postoje zarezi,potrebno je ukloniti ih
                match[1] = match[1].replace(/,/g, ' ');

            functions.push({ selector: match[1], event: match[2], startIndex: scriptCode.search(match[1]) });
        }
        extractjQueryFunctionBody(scriptCode, functions)
      })
    }
  }

  const extractjQueryFunctionBody = (scriptCode, functions) => {
    functions.forEach((func) => {
        var openBracesCount = 0;
        var closedBracesCount = 0;
        var functionCode = '';

      for(let i = func.startIndex; i < scriptCode.length ; i++) {
        var char = scriptCode[i];
        
        if(char === '{')
          openBracesCount++;
        else if (char === '}')
          closedBracesCount++;
        if(openBracesCount > closedBracesCount)
          functionCode += char;
        if(openBracesCount === closedBracesCount && openBracesCount > 0) {
          func.body = functionCode.substring(1);
          break;
        }           
      }     
    })
    executejQueryFunctions(functions);
}

const executejQueryFunctions = (functions) => {
  functions.forEach((func) => {
    const selector = func.selector;
    const event = func.event;
    const body = func.body;

    const elements = tinymce.activeEditor.getDoc().querySelectorAll(selector);
    elements.forEach(el => {
      el.addEventListener(event, function(event) {
        event.preventDefault();
        eval(body);
    });
    })
});
}

  return (
    <>
      {!file ? (
        <div style={{ padding: "15px" }} {...getRootProps()}>
          <input {...getInputProps()} />
          <h5>Убаците ХТМЛ фајл</h5>
        </div>
      ) : (
        <>
          <div>
          <div className="flex-text-editor">
            <div className="row">
              <div className="col-md-10">
                <Editor
                  id="kljuc"
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  initialValue={content}
                  init={{
                    height: 530,
                    menubar: true,
                    toolbar: options
                      ? "undo redo visualchars visualblocks | formatselect | " +
                        "bold italic underline superscript subscript strikethrough forecolor backcolor | casechange blocks fonts fontfamily fontsize |alignleft aligncenter alignright alignjustify |" +
                        " outdent indent bullist numlist|  link image |" +
                        "removeformat | code searchreplace"
                      : "formatselect",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }" +
                      "a {text-decoration: underline}",
                    plugins:
                      " importcss table link searchreplace code lists image visualchars visualblocks quickbars",
                    content_css: window.location.origin + "/glasnik.css",
                    content_style: contentStyle,
                    importcss_append: true,
                    entity_encoding: "raw",
                    // selector: "textarea",
                    quickbars_selection_toolbar:
                      "bold italic underline fonts fontfamily fontsize forecolor backcolor| blocks |alignleft aligncenter alignright alignjustify  | quicklink ",
                    table_toolbar:
                      "tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
                      valid_elements: '*[*]',   
                      //extended_valid_elements: 'script[src|async|defer|type|charset]',
                      paste_data_images: false,
                      setup:
                      options &&
                      function (editor) {
                        var scriptLoader = new tinymce.dom.ScriptLoader();
                        scripts?.forEach(script => {
                          if(script.src !== "")
                            scriptLoader.add(script.src)
                            scriptLoader.loadQueue();
                        })                         
                        editor.on("keyup init change undo redo", function (e) {
                          updateHTML(editor.getContent());
                        });
                        editor.on("click", function (e) {
                          clickOnText(editor.getContent());
                        });
                        editor.addShortcut("meta+s", "Save", () => {
                          editFile();
                        });
                        editor.addShortcut("meta+e", "Center", () => {
                          var classCenter = Object.entries(tinymce.activeEditor?.formatter.get()).filter(([key, value]) => value[0].title === "p.centar")[0]
                          console.log(classCenter[0])
                          addFormatStyle(classCenter[0], "p.centar");
                        });
                        document.addEventListener("keydown", function (event) {
                          //kad se klikne ctrl s ako je van fokusa editora
                          if (event.ctrlKey && event.code === "KeyS") {
                            event.preventDefault();
                            editFile();
                          }
                        });
                        editor.on('keydown', function(e) {
                          if (e.key === "Enter") {
                            setTimeout(() => {
                              editor.execCommand('RemoveFormat'); //clear formatting
                            }, 100);
                            
                          }
                        });
                      },
                  }}
                />
              </div>
              <div className="col-md-2">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Претражи класе..."
                  onChange={(e) => {
                    setSearchFormats(e.target.value);
                    searchFormats(e.target.value);
                  }}
                  value={seachFormats}
                  style={{ marginLeft: "-10px" }}
                />
                <div className="classesContainer">
                  {" "}
                  {/*div za prikaz css klasa*/}
                  {
                    formatsSideClasses().map(([key, val]) => {
                      var combinedClasses = val[0].title
                        ? val[0].title.includes("p.")
                          ? val[0].title.substring(2) + " itemList"
                          : val[0].title + " itemList"
                        : "";
                      return (
                        <div key={key} className="divList">
                          <ul
                            key={key}
                            className={combinedClasses}
                            onClick={() => addFormatStyle(key, val[0].title)}
                          >
                            {val[0].title}
                          </ul>
                        </div>
                      );
                    })

                    //ako bude nekad trebalo da moze da se menja redosled klasa
                    //   seachFormats ? filterLista.map((x) => {return <ul className="itemList" onClick={() => addFormat(x)}>{x}</ul>})
                    //   : formats.map((x, index) =><div className="itemList"
                    //   onDragStart={(e) => dragStart(e, index)}
                    //   onDragEnter={(e) => dragEnter(e, index)}
                    //   onDragEnd={drop}
                    //   key={index}
                    //   draggable>
                    //     {x}
                    // </div>)
                  }
                </div>
              </div>
            </div>

            <div>
              <button
                type="button"
                className="btnTiny btn-primary"
                style={{ margin: "3px" }}
                onClick={editFile}
              >
                Сачувај измене
              </button>
              <button
                type="button"
                className="btnTiny btn-primary"
                style={{ margin: "3px" }}
                onClick={cancel}
              >
                Одустани од измена
              </button>
              <button
                type="button"
                className="btnTiny btn-primary"
                style={{ margin: "3px" }}
                onClick={addBorder}
              >
                Додај бордер свим табелама
              </button>
              <button
                type="button"
                className="btnTiny btn-primary"
                style={{ margin: "3px" }}
                onClick={addBorderSelect}
              >
                Додај бордер селектованој табели
              </button>
              <button
                type="button"
                className="btnTiny btn-primary"
                style={{ margin: "3px" }}
                onClick={removeBorder}
              >
                Уклони бордер свим табелама
              </button>
              <button
                type="button"
                className="btnTiny btn-primary"
                style={{ margin: "3px" }}
                onClick={removeBorderSelect}
              >
                Уклони бордер селектованој табели
              </button>
              <button
                type="button"
                className="btnTiny btn-primary"
                style={{ margin: "3px" }}
                onClick={removeAlign}
                title="Уклони поравнања у целом ХТМЛ-у"
              >
                Уклони поравнања
              </button>
              <button
                type="button"
                className="btnTiny btn-primary"
                style={{ margin: "3px" }}
                onClick={editHead}
                hidden={!hideHtmlHead}
              >
                Измени head
              </button>
              <button
                type="button"
                className="btnTiny btn-primary"
                style={{ margin: "3px" }}
                onClick={cancelEditHead}
                hidden={hideHtmlHead}
              >
                Затвори измену head-а
              </button>
              {options && (
                <button
                  type="button"
                  className="btnTiny btn-primary"
                  style={{ margin: "3px" }}
                  onClick={showFindReplace}
                  title="Find and replace"
                >
                  <FontAwesomeIcon icon={solid("magnifying-glass")} />
                </button>
              )}
            </div>
            <div className="searcReplaceBox" hidden={hideFindReplaceBox}>
              {" "}
              {/*box za search replace*/}
              <div className="row">
                <h5 className="col-md-10">Find and Replace</h5>
                <button
                  type="button"
                  className="btn col-md-2 closebtn"
                  style={{ marginTop: "-5px" }}
                  onClick={hideFindReplace}
                >
                  <FontAwesomeIcon icon={solid("xmark")} />
                </button>
              </div>
              <div>
                <input
                  style={{ marginBottom: "5px" }}
                  className="form-control"
                  type="text"
                  name="description"
                  placeholder="Find"
                  value={textToSearch}
                  onChange={(event) => onChangeSearch(event.target.value)}
                />
              </div>
              <input
                className="form-control"
                type="text"
                name="description"
                placeholder="Replace with"
                value={textToReplace}
                onChange={(event) => setTextToReplace(event.target.value)}
              />
              <button
                type="button"
                className="btnTiny btn-primary"
                style={{ margin: "3px" }}
                onClick={searchText}
                disabled={!textToSearch ? true : false}
              >
                Find
              </button>
              <button
                type="button"
                className="btnTiny btn-primary"
                style={{ margin: "3px" }}
                onClick={replaceText}
                disabled={textToReplace === "" || !textToSearch ? true : false}
              >
                Replace
              </button>
              <button
                type="button"
                className="btnTiny btn-primary"
                style={{ margin: "3px" }}
                onClick={replaceAllText}
                disabled={textToReplace === "" || !textToSearch ? true : false}
              >
                Replace all
              </button>
            </div>
            {options && (
              <div className="overflow-auto">
                <div
                  onClick={() => clickOnHtml()}
                  id="textareaDiv"
                  hidden={hideHtmlBody}
                  className="HTMLContainer textareaContainer"
                >
                  <HighlightWithinTextarea
                    value={textareaContent}
                    onChange={updateEditor}
                    highlight={highlight}
                  />
                </div>
                <textarea
                  id="textAreaHead"
                  defaultValue={headContent}
                  hidden={hideHtmlHead}
                  className="HTMLContainer"
                  onChange={(e) => updateHead(e.target.value)}
                ></textarea>
              </div>
            )}
          </div>
          </div>
        </>
      )}
    </>
  );
};

export default TinyMCE;
